import React, { useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import InputField from '../common/components/Form/InputField'
import SubtitleSmall from '../common/components/typography/SubtitleSmall'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import { formatPhone } from '../common/utils/formatters'
import { Contact } from '../types'
import styles from './ContactForm.module.scss'
import { ContactFormData, contactFormSchema } from './contactForm.utils'

type ContactFormProps = {
  contact?: Contact
  onAddEditContact: (data: ContactFormData) => void
}

const contactToFormValues = (contact?: Contact) => ({
  firstName: contact?.firstName || '',
  lastName: contact?.lastName || '',
  phone: contact?.mainNumber?.number
    ? formatPhone(contact?.mainNumber?.number)
    : '',
  primaryEmail: contact?.primaryEmail || '',
})

export default function ContactForm({
  contact,
  onAddEditContact,
}: ContactFormProps) {
  const conversationId = useCurrentConversationId()
  const initialValues = useRef(contactToFormValues(contact))
  useEffect(() => {
    initialValues.current = contactToFormValues(contact)
  }, [contact])

  const { control, reset, getValues } = useForm<ContactFormData>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(contactFormSchema),
    defaultValues: initialValues.current,
  })

  useEffect(() => {
    reset(initialValues.current)
  }, [contact?.id, reset])

  const handleSave = async () => {
    const formData = getValues()
    try {
      const validated = await contactFormSchema.validate(formData)
      onAddEditContact(validated as ContactFormData)
    } catch (error) {
      // just validation errors
    }
  }

  return (
    <>
      <SubtitleSmall className={styles.blockHeader}>
        Lead Information
      </SubtitleSmall>
      <form
        data-testid="contactForm"
        onChange={handleSave}
        className={styles.contactForm}
      >
        <InputField
          name="firstName"
          control={control}
          id="firstName"
          label="First Name"
          containerClassName={styles.textInput}
          disabled={!conversationId}
        />
        <InputField
          name="lastName"
          control={control}
          id="lastName"
          label="Last Name"
          containerClassName={styles.textInput}
          disabled={!conversationId}
        />
        <InputField
          name="phone"
          control={control}
          id="phone"
          label="Mobile Phone"
          containerClassName={styles.textInput}
          disabled
        />
        <InputField
          name="primaryEmail"
          control={control}
          id="primaryEmail"
          label="Email"
          containerClassName={styles.textInput}
          disabled={!conversationId}
        />
      </form>
    </>
  )
}
