import React from 'react'

import classnames from 'classnames'

import styles from './BodyLarge.module.scss'

export default function BodyLarge({
  className,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) {
  return <p {...rest} className={classnames(styles.title, className)} />
}
