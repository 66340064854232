import React from 'react'

import { Attachment, Message } from '../../types'
import AttachmentsMessage from './AttachmentsMessage'
import TextMessage from './TextMessage'

export default function MessageComponent({
  message,
  sender,
  onClickMessage,
}: {
  message: Message
  sender?: string
  onClickMessage?: (m: Message, a?: Attachment) => void
}) {
  // TODO: implement review request
  if (message.reviewRequest) {
    return <TextMessage message={message} sender={sender} />
  }
  if (message.attachments.length) {
    return (
      <AttachmentsMessage
        onClickMessage={onClickMessage}
        message={message}
        sender={sender}
      />
    )
  }
  // TODO: implement video links
  if (message.outbound && message.body.includes('https://meet.jit.si')) {
    return <TextMessage message={message} sender={sender} />
  }
  // TODO: implement referral messages
  if (message.messageKind === 'REFERRAL') {
    return <TextMessage message={message} sender={sender} />
  }
  // TODO: implement lead metadata
  if (message.messageKind === 'LEAD_METADATA') {
    return <TextMessage message={message} sender={sender} />
  }
  return <TextMessage message={message} sender={sender} />
}
