import React from 'react'

import BodyLarge from '../../common/components/typography/BodyLarge'
import TitleMedium from '../../common/components/typography/TitleMedium'
import EmptyView from './EmptyView'
import styles from './NoConversationSelected.module.scss'

export function NoConversationSelected() {
  return (
    <div className={styles.container}>
      <div className={styles.svg}>
        <EmptyView />
      </div>
      <div className={styles.text}>
        <TitleMedium>You don’t have a conversation selected</TitleMedium>
        <BodyLarge className={styles.body}>
          Choose from your existing conversations or select a new one.
        </BodyLarge>
      </div>
    </div>
  )
}
