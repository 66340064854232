import React from 'react'

import classnames from 'classnames'

import Caption from '../typography/Caption'
import styles from './Timer.module.scss'

export default function Timer({
  time,
  needsReply,
}: {
  time: string
  needsReply?: boolean
}) {
  return (
    <Caption
      className={classnames(styles.timer, needsReply && styles.needsReply)}
    >
      {time}
    </Caption>
  )
}
