import React from 'react'

import { Loader } from '@kenect-ut/kenect-ui-kit'

import styles from './Message.module.scss'

export default function LoadingThumbnail() {
  return (
    <div className={styles.loadingThumbnail}>
      <Loader size="2px" />
    </div>
  )
}
