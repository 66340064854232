import React from 'react'

import classNames from 'classnames'

import styles from './SubtitleSmall.module.scss'

function SubtitleSmall({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) {
  return (
    <h6 {...rest} className={classNames(styles.subtitle, className)}>
      {children}
    </h6>
  )
}

export default SubtitleSmall
