import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { closeConversation } from '../../../services/managedConversationService/closeConversation'
import useAssignedConversations from './useAssignedConversations'

export default function useCloseConversation() {
  const { refetch: refetchConversations } = useAssignedConversations()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (managedConversationId: number) => {
      await closeConversation(managedConversationId)
      await refetchConversations()
      navigate('/dashboard')
    },
    useErrorBoundary: true,
  })
}
