import { PhoneNumberUtil } from 'google-libphonenumber'
import { DateTime } from 'luxon'

import { Contact } from '../../types'

const phoneUtil = PhoneNumberUtil.getInstance()

export const formatPhone = (phone?: string): string => {
  if (phone) {
    try {
      const number = phoneUtil.parse(`${phone}`, 'US')
      return `+${phoneUtil.formatOutOfCountryCallingNumber(number, 'US')}`
    } catch (e) {
      return `${phone}`
    }
  }
  return ''
}

export function formatSchedulingDate(date: Date | string) {
  const dateMoment = DateTime.fromISO(new Date(date).toISOString())
  return `${dateMoment.toLocaleString({
    weekday: 'short',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  })} at ${dateMoment.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
  })}`
} // 'ddd, MMM DD, YYYY' && 'h:mm A'

export const formatToReadableTimezone = (date = new Date()) => {
  if (Intl?.DateTimeFormat) {
    const dateFormat = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' })
    return dateFormat
      .formatToParts(date)
      .find((part) => part.type === 'timeZoneName')?.value
  }
  // for browsers that do not support Intl
  return date
    .toString()
    .match(/\((.*)\)/)
    ?.pop()
}

export function formatContactName(contact?: Contact) {
  if (!contact) {
    return 'Visitor'
  }
  return (
    [contact.firstName, contact.lastName].filter(Boolean).join(' ') || 'Visitor'
  )
}
