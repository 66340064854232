import React from 'react'

import classnames from 'classnames'

import styles from './TitleMedium.module.scss'

export default function TitleMedium({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) {
  return (
    <h2 {...rest} className={classnames(styles.title, className)}>
      {children}
    </h2>
  )
}
