// Copied from platform-ui
export function getIconAndColor(fileType: string) {
  switch (fileType) {
    case 'application/msword':
      return ['file-doc', 'aqua-4']

    case 'text/csv':
      return ['file-csv', 'aqua-4']

    case 'application/pdf':
      return ['file-pdf', 'red-3']

    case 'application/zip':
      return ['file-zip', 'aqua-4']

    case 'application/vnd.ms-excel':
      return ['file-xls', 'aqua-4']

    case 'application/vnd.ms-powerpoint':
      return ['file-ppt', 'aqua-4']

    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/rtf':
      return ['file-common', 'aqua-4']

    case 'audio/3gpp':
    case 'audio/3gpp2':
    case 'audio/ac3':
    case 'audio/amr':
    case 'audio/amr-nb':
    case 'audio/basic':
    case 'audio/L24':
    case 'audio/mp4':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/vnd.rn-realaudio':
    case 'audio/vnd.wave':
    case 'audio/vorbis':
    case 'audio/webm':
      return ['file-audio', 'blue-2']

    case 'image/bmp':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
      return ['file-image', 'blue-2']

    case 'text/calendar':
    case 'text/directory':
    case 'text/richtext':
    case 'text/rtf':
    case 'text/vcard':
    case 'text/x-vcard':
      return ['file-text', 'aqua-4']

    case 'video/3gpp':
    case 'video/3gpp-tt':
    case 'video/3gpp2':
    case 'video/H261':
    case 'video/H263':
    case 'video/H263-1998':
    case 'video/H263-2000':
    case 'video/H264':
    case 'video/mp4':
    case 'video/mpeg':
    case 'video/quicktime':
    case 'video/webm':
      return ['file-video', 'blue-2']

    default:
      return ['file-common', 'aqua-4']
  }
}
