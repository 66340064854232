import React from 'react'

import { TextArea } from '@kenect-ut/kenect-ui-kit'
import type { Control } from 'react-hook-form'
import { useController } from 'react-hook-form'

import ValidationMessage from './ValidationMessage'

interface InputFieldAreaProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  useValidationSpacer?: boolean
  [key: string]: unknown
}

function InputFieldArea({
  name,
  control,
  useValidationSpacer = false,
  ...rest
}: InputFieldAreaProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  return (
    <>
      <TextArea
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <ValidationMessage
        error={error?.message}
        useSpacer={useValidationSpacer}
      />
    </>
  )
}

export default InputFieldArea
