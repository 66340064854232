import React from 'react'

import classNames from 'classnames'

import styles from './Notes.module.scss'

export function DealerNotesToAgent({ notes }: { notes: string }) {
  return (
    <div className={classNames(styles.fade, styles.noteCard)}>
      <div
        className="kui-body-large"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: notes }}
      />
    </div>
  )
}
