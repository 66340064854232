import React from 'react'

export const parseLinks = (string: string) => {
  // copied over from platform-ui
  const lowerString = string || ''
  const regex = /(https?:\/\/[^\s]+|www.[^\s]+)/gi
  const messageSplit = lowerString.split(regex)
  return messageSplit.map((stringPiece, i) => {
    const href = stringPiece.includes('https://')
      ? stringPiece
      : `https://${stringPiece}`
    return i % 2 === 0 ? (
      stringPiece
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <a href={href} key={i} target="_blank" rel="noopener noreferrer">
        {stringPiece}
      </a>
    )
  })
}
