import React, { KeyboardEvent } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@kenect-ut/kenect-ui-kit'
import { useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import * as yup from 'yup'

import type {
  Conversation,
  CreateMessageDto,
  ReplyMessageTemplate,
} from '../../types'
import styles from './CompositionForm.module.scss'
import TopActionsBar from './TopActionsBar'

interface CompositionFormProps {
  conversation?: Conversation
  resourceLinkTemplates: ReplyMessageTemplate[]
  quickReplyTemplates: ReplyMessageTemplate[]
  onSend: (msg: CreateMessageDto) => void
  locationId?: number
}

const compositionSchema = yup.object().shape({
  message: yup.string().trim().required('Required'),
})
interface CompositionFormData {
  message: string
}

const defaultSubject = 'SMS Message'

function CompositionForm({
  conversation,
  resourceLinkTemplates,
  quickReplyTemplates,
  onSend,
  locationId,
}: CompositionFormProps) {
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<CompositionFormData>({
    criteriaMode: 'all',
    mode: 'all',
    resolver: yupResolver(compositionSchema),
  })

  const handleMessageSend = async (values: CompositionFormData) => {
    if (!conversation) {
      return
    }
    onSend({
      channelId: conversation?.channelId,
      phone: conversation?.remoteDestination,
      message: values.message,
      subject: defaultSubject,
      locationId: conversation?.locationId,
    })
    reset()
  }

  const handleKeyPressSubmission = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey && getValues().message?.trim()) {
      e.preventDefault()
      handleMessageSend(getValues())
    }
  }
  const handleSelectActionItemOption = (template: ReplyMessageTemplate) => {
    setValue('message', template.template, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: false,
    })
  }

  return (
    <form
      className={styles.formContainer}
      onSubmit={handleSubmit(handleMessageSend)}
    >
      <div className={styles.topButtonContainer}>
        <TopActionsBar
          resourceLinkTemplates={resourceLinkTemplates}
          quickReplyTemplates={quickReplyTemplates}
          locationId={locationId}
          onOptionSelect={handleSelectActionItemOption}
        />
      </div>
      <div className={styles.botInputsContainer}>
        <TextareaAutosize
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('message')}
          id="message"
          onKeyDown={handleKeyPressSubmission}
          className={styles.inputField}
          placeholder="Type your message"
          maxLength={1600}
          maxRows={3}
          disabled={!conversation || isSubmitting}
        />

        <Button
          type="submit"
          size="large"
          className={styles.sendButton}
          isLoading={isSubmitting}
          disabled={!conversation || !isDirty || !isValid}
        >
          Send
        </Button>
      </div>
    </form>
  )
}

export default CompositionForm
