import React from 'react'

import Modal from '../../common/components/Modal'
import { useAttachmentImage } from '../../common/hooks/services/inboxService/useAttachmentImage'
import FailedThumbnail from './FailedThumbnail'
import LoadingThumbnail from './LoadingThumbnail'
import styles from './Message.module.scss'

export default function ImageModal({
  isOpen,
  onClose,
  messageId,
  attachmentId,
}: {
  isOpen: boolean
  onClose: () => void
  messageId?: number
  attachmentId?: number
}) {
  const { data, isLoading, error } = useAttachmentImage({
    messageId,
    attachmentId,
    enabled: isOpen,
  })
  let content
  if (isLoading) {
    content = <LoadingThumbnail />
  } else if (error || typeof data !== 'string') {
    content = <FailedThumbnail />
  } else {
    content = (
      <img
        className={styles.image}
        src={data}
        alt="Full Sized Attachment Preview"
      />
    )
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {content}
    </Modal>
  )
}
