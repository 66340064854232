import { useCallback, useState } from 'react'

export default function useLocalStorage<T>(
  key: string,
  initialValue?: T,
): [T, (newValue: T) => void] {
  const [value, setValue] = useState<T>(() => {
    try {
      const retrieved = localStorage.getItem(key)
      if (retrieved) {
        return JSON.parse(retrieved)
      }
      return initialValue
    } catch (e) {
      return initialValue
    }
  })
  const set = useCallback(
    (newValue: T) => {
      localStorage.setItem(key, JSON.stringify(newValue))
      setValue(newValue)
    },
    [key],
  )
  return [value, set]
}
