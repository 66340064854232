import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { doc, onSnapshot, getFirestore } from 'firebase/firestore'
import debounce from 'lodash/debounce'

import { setAppBadge } from '../../../badgeUtil'
import { getUnassignedConversationsSummary } from '../../../services/inboxService/conversationQueue'
import app from '../../../utils/firebase'
import { useUser } from '../../api/authService/useUser'

const QUEUE = 'QUEUE'

export default function useConversationQueueSummary() {
  return useQuery([QUEUE], getUnassignedConversationsSummary, {
    useErrorBoundary: true,
    refetchInterval: 60 * 1000,
    onSuccess: (data) => {
      setAppBadge(data.queueDepth || 0)
    },
  })
}

export function useSubscribeToQueue() {
  const query = useConversationQueueSummary()
  const debouncedRefetch = useMemo(
    () => debounce(query.refetch, 500),
    [query.refetch],
  )
  const { data: user } = useUser()
  const locationId = user?.userLocationIds[0]
  useEffect(() => {
    if (!locationId) {
      return undefined
    }
    return onSnapshot(
      doc(
        getFirestore(app),
        'managedConversationQueues',
        locationId.toString(),
      ),
      () => {
        debouncedRefetch()
      },
    )
  }, [debouncedRefetch, locationId])
}
