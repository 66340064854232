/**
 * A custom implementation of yup-phone: https://github.com/abhisekp/yup-phone/
 */
import gPhoneNumber from 'google-libphonenumber'
import * as Yup from 'yup'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for phone number validity.
     * @example
     * import '~/phoneValidator'
     * phone: yup.string().required().phone()
     *
     * @param {String} [countryCode=US] The country code to check against.
     * @param {Boolean} [strict=false] How strictly should it check.
     * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
     */
    phone(
      countryCode?: string,
      strict?: boolean,
      errorMessage?: string,
    ): StringSchema
  }
}

const YUP_PHONE_METHOD = 'phone'

const isValidCountryCode = (countryCode: string): boolean =>
  typeof countryCode === 'string' && countryCode.length === 2

Yup.addMethod(
  Yup.string,
  YUP_PHONE_METHOD,
  function yupPhone(countryCode = 'US', strict = false, errorMessage = '') {
    const message = isValidCountryCode(countryCode)
      ? `\${path} must be a valid phone number for region ${countryCode}`
      : // eslint-disable-next-line no-template-curly-in-string
        '${path} must be a valid phone number.'
    const errMsg =
      typeof errorMessage === 'string' && errorMessage ? errorMessage : message

    // @ts-expect-error non-null assertion
    return this.test(YUP_PHONE_METHOD, errMsg, (value: string) => {
      try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode)

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false
        }

        const regionCodeFromPhoneNumber =
          phoneUtil.getRegionCodeForNumber(phoneNumber)

        /* check if the countryCode provided should be used as
          default country code or strictly followed
        */
        return strict
          ? phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
          : phoneUtil.isValidNumberForRegion(
              phoneNumber,
              regionCodeFromPhoneNumber,
            )
      } catch {
        return false
      }
    })
  },
)
