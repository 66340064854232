import React from 'react'

import { Button, Icon, ModalAlert } from '@kenect-ut/kenect-ui-kit'

import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import useModal from '../common/hooks/useModal'

interface IForwardLeadProps {
  isQualifiedContact: boolean
  onForwardLead: (managedConversationId?: number) => Promise<void>
}

function ForwardLead({ onForwardLead, isQualifiedContact }: IForwardLeadProps) {
  const conversationId = useCurrentConversationId()
  const { hide, isVisible, show } = useModal()
  const { data: conversation } = useConversation(conversationId)

  return (
    <div id="forwardLead">
      <Button
        size="large"
        disabled={
          !conversationId ||
          !conversation?.managedConversationId ||
          !isQualifiedContact
        }
        onClick={() => show()}
      >
        Forward Qualified Lead
      </Button>
      {/* @ts-expect-error $KUI PROPS ERROR */}
      <ModalAlert
        id="forwardLead"
        icon={<Icon name="alert-triangle" size={20} />}
        color="blue"
        title="Are you sure you want to forward this lead?"
        isOpen={isVisible}
        message="The conversation will be sent to the Kenect app (and customer’s CRM if configured). You will no longer be able to access this conversation."
        parentSelector="#forwardLead"
        closeButtonLabel="Cancel"
        primaryButtonLabel="Forward Lead"
        onCloseButtonClicked={hide}
        onPrimaryButtonClicked={() => {
          onForwardLead(conversation?.managedConversationId)
          hide()
        }}
      />
    </div>
  )
}

export default ForwardLead
