import React from 'react'

import classnames from 'classnames'

import styles from './Caption.module.scss'

export default function Caption({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) {
  return (
    <p {...rest} className={classnames(styles.caption, className)}>
      {children}
    </p>
  )
}
