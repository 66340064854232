import { useMemo } from 'react'

import { useLocationDetails } from '../common/hooks/services/locationService/useLocationDetails'
import { ReplyMessageTemplate } from '../types'

export function useResourceOptions(locationId?: number) {
  const { data: locationInformation } = useLocationDetails(locationId)

  return useMemo(() => {
    if (locationInformation?.attachmentUrls) {
      return Object.keys(locationInformation.attachmentUrls).map(
        (key, index) => ({
          id: index,
          title: `${key} | ${locationInformation.attachmentUrls[key]}`,
          template: locationInformation.attachmentUrls[key],
          type: 'ResourceLinks',
          locationId: locationInformation.providerLocationId,
          organizationId: locationInformation.organizationId,
        }),
      )
    }
    return []
  }, [locationInformation]) as ReplyMessageTemplate[]
}
