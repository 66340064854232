import { useMemo } from 'react'

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CONVERSATION,
  MINE,
} from '../common/hooks/services/managedConversationService/useAssignedConversations'
import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import type { Conversation } from '../types'
import {
  getContactsByIds,
  getLocationTeams,
  markAsQualified,
  upsertContact,
} from './api'
import { buildContactForUpsert, ContactFormData } from './contactForm.utils'

const BY_ID = 'BY_ID'
const CONTACT = 'CONTACT'
export const LOCATION = 'LOCATION'
const TEAMS = 'TEAMS'

// TODO: replace file with actual managed texting hooks

function useContactsByIds(contactIds?: number[]) {
  const client = useQueryClient()
  return useQuery(
    [CONTACT, BY_ID, contactIds],
    () => contactIds && getContactsByIds(contactIds),
    {
      useErrorBoundary: true,
      enabled: !!contactIds?.length,
      onSuccess: (byId) => {
        if (byId) {
          Object.keys(byId).forEach((id) =>
            client.setQueryData([CONTACT, +id], byId[+id]),
          )
        }
      },
    },
  )
}

export function useContact(id?: number) {
  return useQuery(
    [CONTACT, id],
    async () => {
      if (id) {
        const contactsById = await getContactsByIds([id])
        return contactsById[id]
      }
      return undefined
    },
    {
      enabled: !!id,
    },
  )
}

export function useContactsByConversations(conversations?: Conversation[]) {
  const ids = useMemo(
    () =>
      conversations
        ?.map((c) => c.contactIds)
        .flat()
        .filter(Boolean),
    [conversations],
  )
  return useContactsByIds(ids)
}

export const useContactUpsert = (options = {}) => {
  const queryClient = useQueryClient()
  const conversationId = useCurrentConversationId()
  const { data: conversation } = useConversation(conversationId)
  const { data: contact } = useContact(conversation?.contactIds?.[0])

  return useMutation(
    (formData: ContactFormData) => {
      return upsertContact(
        buildContactForUpsert({
          contact: {
            ...(contact || {}),
            ...formData,
            phone: formData.phone || contact?.mainNumber.number || '',
          },
          options: {
            locationId: conversation?.locationId || 0,
            contactId: contact?.id,
          },
        }),
      )
    },
    {
      // TODO: add contact to cache during onMutate
      onSuccess: () => {
        queryClient.invalidateQueries([CONTACT])
      },
      ...options,
    },
  )
}

export const useLocationTeams = (locationId?: number) => {
  return useQuery(
    [LOCATION, locationId, TEAMS],
    async () => {
      if (!locationId) {
        return undefined
      }
      return getLocationTeams(locationId)
    },
    {
      enabled: !!locationId,
    },
  )
}

export const useMarkLeadAsQualified = (
  options?: Omit<
    UseMutationOptions<void, unknown, number, unknown>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation(markAsQualified, {
    onSuccess: () => {
      // This should remove the conversation from an agent's
      queryClient.invalidateQueries([CONVERSATION, MINE])
    },
    useErrorBoundary: true,
    ...options,
  })
}
