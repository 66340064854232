import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Conversation } from '../../../../types'
import { assignTeam } from '../../../services/inboxService/assignTeam'
import {
  CONVERSATION,
  MINE,
} from '../managedConversationService/useAssignedConversations'

function useAssignTeam() {
  const queryClient = useQueryClient()
  return useMutation(assignTeam, {
    useErrorBoundary: true,
    onMutate: async (teamUpdate) => {
      await queryClient.cancelQueries({ queryKey: [CONVERSATION, MINE] })
      const previousConversations: Conversation[] | undefined =
        queryClient.getQueryData([CONVERSATION, MINE])
      queryClient.setQueryData(
        [CONVERSATION, MINE],
        (prev: Conversation[] | undefined) =>
          prev?.map((convo) => {
            if (convo.id === teamUpdate.conversationId) {
              return { ...convo, assignedTeamId: teamUpdate.teamId }
            }
            return convo
          }),
      )
      return { previousConversations }
    },
    onError: (err, teamUpdate, context) => {
      queryClient.setQueryData(
        [CONVERSATION, MINE],
        context?.previousConversations,
      )
    },
  })
}

export default useAssignTeam
