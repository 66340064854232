import React from 'react'

import classnames from 'classnames'

import Timer from '../common/components/Timer/Timer'
import BodySmall from '../common/components/typography/BodySmall'
import SubtitleLarge from '../common/components/typography/SubtitleLarge'
import TitleSmall from '../common/components/typography/TitleSmall'
import useDurationSince from '../common/hooks/useDurationSince'
import { formatContactName } from '../common/utils/formatters'
import { Contact, Conversation } from '../types'
import styles from './AssignedConversation.module.scss'

interface Props {
  conversation: Conversation
  onClick: () => void
  contact?: Contact
  isSelected: boolean
  isLoadingContact: boolean
  organizationName?: string
  locationName?: string
}

export function AssignedConversation({
  conversation,
  onClick,
  contact,
  isSelected,
  organizationName,
  locationName = '',
  isLoadingContact,
}: Props) {
  const Title = isSelected ? TitleSmall : SubtitleLarge
  const contactName = isLoadingContact ? '' : formatContactName(contact)
  const contactAndLocation = locationName
    ? `${contactName} | ${locationName}`
    : contactName
  const diff = useDurationSince(conversation.latestSentAt).toFormat('mm:ss')
  return (
    <li className={styles.container}>
      <button
        type="button"
        onClick={onClick}
        className={classnames(styles.button, isSelected && styles.selected)}
      >
        <div className={styles.inner}>
          <Title className={styles.title}>{organizationName}</Title>
          <Timer time={diff} needsReply={!conversation.userResponded} />
        </div>
        <BodySmall className={styles.name}>
          {isLoadingContact ? '' : contactAndLocation}
        </BodySmall>
      </button>
    </li>
  )
}
