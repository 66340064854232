import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react'

import { Loader } from '@kenect-ut/kenect-ui-kit'
import { InfiniteData } from '@tanstack/react-query'

import { LoadingView } from '../common/components/LoadingView'
import { useUser } from '../common/hooks/api/authService/useUser'
import {
  useMessages,
  useSubscribeToMessages,
} from '../common/hooks/services/inboxService/useMessages'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import useModal from '../common/hooks/useModal'
import { Message } from '../types'
import ImageModal from './message/ImageModal'
import MessageComponent from './message/Message'
import styles from './MessengesContainer.module.scss'

function hasReachedTop(element: null | HTMLElement) {
  return (
    element &&
    element.scrollHeight + element.scrollTop - element.offsetHeight < 100
  )
}

function useScrollToBottomOnConversationChange(
  listElement: RefObject<HTMLDivElement>,
  conversationId?: number,
  data?: InfiniteData<Message[]>,
) {
  // when the conversationId changes we want to ensure that we are scrolled
  // all the way to the bottom where the first message appears

  // when the messages first load from the API
  useEffect(() => {
    if (data?.pages.length === 1) {
      listElement.current?.scroll({ top: listElement.current?.scrollHeight })
    }
  }, [listElement, data])

  // when the conversationId changes and the messages are cached
  useEffect(() => {
    listElement.current?.scroll({ top: listElement.current?.scrollHeight })
  }, [listElement, conversationId])
}

export function MessagesContainer() {
  const conversationId = useCurrentConversationId()
  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useMessages(conversationId)
  useSubscribeToMessages(conversationId)
  const listElement = useRef<HTMLDivElement>(null)
  const { isVisible, toggle } = useModal()
  const [[messageId, attachmentId], setImage] = useState<[number, number]>([
    0, 0,
  ])
  useScrollToBottomOnConversationChange(listElement, conversationId, data)
  const { data: user } = useUser()

  const onScroll = () => {
    if (hasReachedTop(listElement.current) && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  return (
    <div onScroll={onScroll} ref={listElement} className={styles.messages}>
      {isLoading && <LoadingView />}
      {isFetchingNextPage && <Loader size="3px" />}
      {data?.pages.map<ReactNode[]>((page) =>
        page.map((message) => (
          <MessageComponent
            onClickMessage={(m, attachment) => {
              if (attachment) {
                setImage([m.id, attachment.id])
                toggle()
              }
            }}
            sender={
              message.senderUserId === user?.userId
                ? user?.displayName || 'Agent'
                : ''
            }
            key={message.id}
            message={message}
          />
        )),
      )}
      <ImageModal
        attachmentId={attachmentId}
        messageId={messageId}
        onClose={toggle}
        isOpen={isVisible}
      />
    </div>
  )
}
