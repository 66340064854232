import React, { useEffect, useMemo } from 'react'

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
} from 'firebase/auth'

import app from '../common/utils/firebase'

const auth = getAuth(app)

interface AuthContextType {
  user: User | null
  ready: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

const AuthContext = React.createContext<AuthContextType>({
  ready: false,
  user: null,
  logout: () => Promise.resolve(),
  login: () => Promise.resolve(),
})

export function AuthProvider({
  children,
  onLogout,
}: {
  children: React.ReactNode
  onLogout?: () => void
}) {
  const [user, setUser] = React.useState<User | null>(null)
  const [ready, setReady] = React.useState<boolean>(false)

  useEffect(
    () =>
      onAuthStateChanged(auth, (nextUser) => {
        if (user && !nextUser) {
          onLogout?.()
        }
        setUser(nextUser)
        setReady(true)
      }),
    [user, onLogout],
  )

  const value = useMemo(
    () => ({
      login: async (email: string, password: string) => {
        const userCredentials = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        )
        setUser(userCredentials.user)
      },
      logout: () => signOut(auth),
      ready,
      user,
    }),
    [ready, user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext)
}
