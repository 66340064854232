import React, { useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import { LoadingView } from '../common/components/LoadingView'
import { useLocationsDetails } from '../common/hooks/services/locationService/useLocationDetails'
import useAssignedConversations, {
  useSubscribeToAssignedConversations,
} from '../common/hooks/services/managedConversationService/useAssignedConversations'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import { useContactsByConversations } from '../contact/hooks'
import { AssignedConversation } from './AssignedConversation'
import styles from './AssignedConversationsContainer.module.scss'

export function AssignedConversationsContainer() {
  const conversationId = useCurrentConversationId()
  const { data: conversations, isLoading: isLoadingConversations } =
    useAssignedConversations()
  const { data: contactsById, isLoading: isLoadingContacts } =
    useContactsByConversations(conversations)
  const navigate = useNavigate()
  useSubscribeToAssignedConversations()
  const locationIds = useMemo(
    () => [...new Set(conversations?.map((c) => c.locationId) || [])],
    [conversations],
  )
  const { data: locationsDetailsById } = useLocationsDetails(locationIds)

  if (conversations?.length === 0) {
    return null
  }

  return (
    <section className={styles.conversations}>
      {isLoadingConversations && <LoadingView />}
      <ul className={styles.list}>
        {conversations?.map((conversation) => (
          <AssignedConversation
            isSelected={conversation.id === conversationId}
            key={conversation.id}
            contact={contactsById?.[conversation.contactIds?.[0]]}
            isLoadingContact={isLoadingContacts}
            conversation={conversation}
            onClick={() => navigate(`/dashboard/lead/${conversation.id}`)}
            organizationName={
              locationsDetailsById?.[conversation.locationId]?.organizationName
            }
            locationName={
              locationsDetailsById?.[conversation.locationId]?.locationName
            }
          />
        ))}
      </ul>
    </section>
  )
}
