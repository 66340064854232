import React, { useCallback, useState } from 'react'

import { Icon } from '@kenect-ut/kenect-ui-kit'
import type { IconNames } from '@kenect-ut/kenect-ui-kit/lib/components/Icon/iconNames'
import classnames from 'classnames'

import { getMessageAttachment } from '../../common/services/inboxService/messages'
import { Attachment, Message } from '../../types'
import styles from './AttachmentMessage.module.scss'
import messageStyles from './Message.module.scss'
import { MessageLabel } from './MessageLabel'
import { getIconAndColor } from './utils'

async function downloadAttachment(attachment: Attachment, messageId: number) {
  const data = await getMessageAttachment({
    attachmentId: attachment.id,
    messageId,
  })
  const href = URL.createObjectURL(data)
  const downloadLink = document.createElement('a')
  downloadLink.setAttribute('href', href)
  downloadLink.setAttribute('download', attachment.name)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
}

function useDownload(attachment: Attachment, messageId: number) {
  const [isDownloading, setIsDownloading] = useState(false)
  const [error, setError] = useState<unknown>()
  const download = useCallback(async () => {
    setIsDownloading(true)
    try {
      await downloadAttachment(attachment, messageId)
      setIsDownloading(false)
    } catch (e) {
      setError(e)
    }
  }, [attachment, messageId])
  return {
    download,
    isDownloading,
    error,
  }
}

export default function AttachmentMessage({
  attachment,
  message,
  sender,
}: {
  attachment: Attachment
  message: Message
  sender?: string
}) {
  const { contentType, name } = attachment
  const [icon, color] = getIconAndColor(contentType)
  const { download, isDownloading, error } = useDownload(attachment, message.id)

  if (error) {
    throw error
  }

  return (
    <div
      className={classnames(
        messageStyles.messageContainer,
        message.outbound ? messageStyles.outbound : messageStyles.inbound,
      )}
    >
      <button
        type="button"
        disabled={isDownloading}
        onClick={download}
        className={classnames(
          styles.attachmentBubble,
          message.outbound ? styles.outgoing : styles.incoming,
        )}
      >
        <Icon
          className={styles.fileTypeIcon}
          style={{ color: `var(--${color})` }}
          name={icon as IconNames}
        />
        <span className={styles.fileFriendlyName}>{name}</span>
      </button>
      <MessageLabel sender={sender} message={message} />
    </div>
  )
}
