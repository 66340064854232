import { useCallback, useEffect, useRef } from 'react'

export default function usePlaySound(url: string) {
  const soundRef = useRef(new Audio(url))
  useEffect(() => {
    soundRef.current = new Audio(url)
  }, [url])

  return useCallback(() => {
    if (soundRef.current.paused) {
      soundRef.current.play()
    } else {
      soundRef.current.currentTime = 0
    }
  }, [])
}
