import { useEffect, useState } from 'react'

export function useCurrentLeadTime() {
  const [currentTime, setCurrentTime] = useState(() => new Date())
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [setCurrentTime])
  return currentTime
}
