import React from 'react'

import classnames from 'classnames'

import BodyLarge from '../../common/components/typography/BodyLarge'
import { parseLinks } from '../../common/utils/parseLinks'
import { Message } from '../../types'
import styles from './Message.module.scss'
import { MessageLabel } from './MessageLabel'

export default function TextMessage({
  message,
  sender,
  notSupported,
}: {
  message: Message
  sender?: string
  notSupported?: boolean
}) {
  // messages with attachments can first come in with empty body and empty
  // attachments array until the upload syncs and another firestore update occurs
  if (!message.body) {
    return null
  }
  return (
    <div
      style={{
        backgroundColor: notSupported ? 'whitesmoke' : 'transparent',
        borderRadius: 10,
        padding: notSupported ? 10 : 0,
      }}
      className={classnames(
        styles.messageContainer,
        message.outbound ? styles.outbound : styles.inbound,
      )}
    >
      {notSupported && (
        <BodyLarge style={{ color: 'red' }}>
          This message type is not supported yet
        </BodyLarge>
      )}
      <div
        className={classnames(
          styles.message,
          message.outbound ? styles.outbound : styles.inbound,
        )}
      >
        <BodyLarge>{parseLinks(message.body)}</BodyLarge>
      </div>
      <MessageLabel sender={sender} message={message} />
    </div>
  )
}
