import { useCallback, useEffect, useRef, useState } from 'react'

import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

export function useRegisterServiceWorker() {
  const serviceWorker = useRef<ServiceWorker | null>()
  const [requiresUpdate, setRequiresUpdate] = useState(false)
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    function onUpdate(registration: ServiceWorkerRegistration) {
      serviceWorker.current = registration.waiting
      setRequiresUpdate(!!registration.waiting)
    }
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate,
    })
  }, [])

  const updateApp = useCallback(async () => {
    setUpdating(true)
    newrelic?.addPageAction('Update app clicked')
    serviceWorker.current?.postMessage({ type: 'SKIP_WAITING' })
    try {
      const cacheNames = await caches.keys()
      await Promise.all(cacheNames.map(caches.delete))
    } catch (e) {
      console.log(e)
    } finally {
      // Using a setTimeout to get around an issue where an immediate
      // refresh isn't updating the app
      // TODO: look into why immediate refresh produces stale assets
      setTimeout(() => {
        window.location.reload()
        setRequiresUpdate(false)
        setUpdating(false)
      }, 1000)
    }
  }, [])

  return {
    requiresUpdate,
    updateApp,
    updating,
  }
}
