import React from 'react'

import BodySmall from '../common/components/typography/BodySmall'
import { formatPhone } from '../common/utils/formatters'
import { LocationDetailsDto } from '../types'
import styles from './Dealer.module.scss'

export function DealerAddressListItem({
  location,
}: {
  location: LocationDetailsDto
}) {
  const { internalName, address, address2, city, state, zip, phone } = location
  const uniqueAddress2 = !!address2 && address2 !== address
  return (
    <li className={styles.locationListItem}>
      <BodySmall className="internalName">
        <strong>{internalName}</strong>
      </BodySmall>
      <BodySmall>{address}</BodySmall>
      {uniqueAddress2 && <BodySmall>{address2}</BodySmall>}
      <BodySmall>
        {city}, {state} {zip}
      </BodySmall>
      {phone && <BodySmall>{formatPhone(phone)}</BodySmall>}
    </li>
  )
}
