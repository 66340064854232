import { getAuth } from 'firebase/auth'

import app from '../common/utils/firebase'

/**
 * HTTP client for the app.
 * @param {string} input Usually the endpoint
 * @param init Insert Method and body here. Note: Auth token and Content type a already setup.
 * @returns Promise<T>
 */
export default async function apiFetch<T>(
  input: Request | string,
  init?: RequestInit,
  retryCount = 1,
): Promise<T> {
  const headers: HeadersInit = new Headers(init?.headers)
  headers.set('Content-Type', 'application/json')
  headers.set(
    'authorization',
    `Bearer ${await getAuth(app).currentUser?.getIdToken()}`,
  )

  const response = await fetch(input, { ...init, headers })
  if (response.status === 401) {
    if (retryCount === 0) {
      await getAuth(app).signOut()
      throw new Error('Unauthenticated')
    }
    return apiFetch(input, init, retryCount - 1)
  }
  if (response.ok) {
    // TODO: handle blob/other file types
    const contentType = response.headers.get('content-type')
    if (contentType?.includes('application/json')) {
      return response.json()
    }
    if (response.headers.get('content-type')) {
      return response.blob() as T
    }
    return response.text() as T
  }
  const errorMessage = await response.text()
  throw new Error(errorMessage)
}
