import React from 'react'

import { BodySmall, Icon, TitleMedium } from '@kenect-ut/kenect-ui-kit'

import styles from './ErrorView.module.scss'

export default function ErrorView({
  title = 'An error has occurred,',
  message = 'please refresh your browser to get things back on track.',
}) {
  return (
    <div className={styles.error}>
      <Icon name="alert-triangle" size={36} />
      <TitleMedium>{title}</TitleMedium>
      <BodySmall>{message}</BodySmall>
    </div>
  )
}
