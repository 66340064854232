import React, { ReactNode } from 'react'

import { Icon, Tooltip } from '@kenect-ut/kenect-ui-kit'

import BodyLarge from '../common/components/typography/BodyLarge'
import styles from './InfoBar.module.scss'

interface Props {
  title: string
  subtitle: ReactNode
  onClose?: () => void
  isLoading: boolean
  closeTooltip?: string
}

export default function InfoBar({
  title,
  subtitle,
  onClose,
  isLoading,
  closeTooltip = 'Close conversation',
}: Props) {
  return (
    <div className={styles.bar}>
      <BodyLarge>
        {!isLoading && (
          <>
            <strong>{title}</strong> {subtitle}
          </>
        )}
      </BodyLarge>
      {!isLoading && onClose && (
        <Tooltip content={closeTooltip} placement="top-end">
          <button className={styles.close} type="button" onClick={onClose}>
            <Icon name="add-circle" size={16} />
          </button>
        </Tooltip>
      )}
    </div>
  )
}
