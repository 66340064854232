import { useMemo } from 'react'

import useAssignedConversations from './useAssignedConversations'

export function useConversation(id?: number) {
  const { data: myConversations, ...rest } = useAssignedConversations()
  const conversation = useMemo(
    () => myConversations?.find((c) => c.id === id),
    [myConversations, id],
  )

  return {
    data: conversation,
    ...rest,
  }
}
