import React, { useCallback, useMemo, useState } from 'react'

import { Dialog } from '@headlessui/react'
import { Button, Calendar, Icon, TimePicker } from '@kenect-ut/kenect-ui-kit'
import classNames from 'classnames'
import { DateTime } from 'luxon'

import Modal from '../common/components/Modal'
import SubtitleSmall from '../common/components/typography/SubtitleSmall'
import useModal from '../common/hooks/useModal'
import { validateDate } from '../common/utils/dateValidator'
import { formatToReadableTimezone } from '../common/utils/formatters'
import type { Contact } from '../types'
import styles from './ContactScheduler.module.scss'

const tenYearsFromNow = new Date()
tenYearsFromNow.setFullYear(tenYearsFromNow.getFullYear() + 10)

type ContactSchedulerProps = {
  contact?: Contact
  onDateSelect: (date: Date) => void
}

function parseNotesForAppointmentDate(note?: string) {
  const dateString = note?.split(/Appointment Scheduled:\n/m)?.[1]
  if (!dateString) return undefined
  // get rid of dayOfWeek and format for Luxon
  const filteredDateString = dateString
    .replace(/([a-zA-Z]+, |,)/, '')
    .replace(' at ', ', ')
    .replace(/\n/g, '')
    .trim()
  // ff is a short localized token: https://moment.github.io/luxon/#/parsing?id=table-of-tokens
  return DateTime.fromFormat(filteredDateString, 'ff').toJSDate()
}

function ContactSchedular({ contact, onDateSelect }: ContactSchedulerProps) {
  // const [visitationAptDate, setVisitationAptDate] = useState<Date | null>(null)

  const dateFromNotes = useMemo(
    () => parseNotesForAppointmentDate(contact?.note),
    [contact?.note],
  )
  const { isVisible, toggle } = useModal()

  const [date, setDate] = useState(
    dateFromNotes ? new Date(dateFromNotes) : new Date(),
  )
  const [time, setTime] = useState(
    dateFromNotes ? new Date(dateFromNotes) : new Date(),
  )

  const dateTime = useMemo(
    () =>
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds(),
      ),
    [date, time],
  )

  const { hasDateError, hasTimeError } = useMemo(
    () => validateDate(dateTime),
    [dateTime],
  )

  const hasDateTimeError = hasDateError || hasTimeError

  const onApplyDate = useCallback(() => {
    if (hasDateTimeError) {
      return
    }

    onDateSelect(dateTime)
    // setVisitationAptDate(dateTime)
    toggle()
  }, [dateTime, hasDateTimeError, toggle, onDateSelect])
  //
  // useEffect(() => {
  //   if (dateFromNotes) {
  //     setVisitationAptDate(dateFromNotes)
  //   }
  //   return () => {
  //     setVisitationAptDate(null)
  //   }
  // }, [contact?.id, dateFromNotes])

  return (
    <>
      <SubtitleSmall className={styles.blockHeader}>
        Schedule Appointment
      </SubtitleSmall>
      <div className={styles.sectionWrapper}>
        <Button
          disabled={!contact}
          onClick={() => toggle()}
          className={styles.schedulerButton}
          variant="tertiary"
        >
          <Icon name="schedule" className={styles.buttonIcon} />
          <p>
            {/* {visitationAptDate */}
            {/*  ? formatSchedulingDate(visitationAptDate) */}
            Add appointment
          </p>
        </Button>
      </div>
      <Modal isOpen={isVisible} onClose={toggle}>
        <Dialog.Panel className={styles.scheduleMessageModalContainer}>
          {/* Modal body */}
          <div className={styles.calendarDateTimeContainer}>
            <Calendar
              date={dateTime}
              onChange={setDate}
              minDate={new Date()}
              maxDate={tenYearsFromNow}
            />
            <div className={styles.timePickerContainer}>
              <Icon name="clock" size={20} />
              <TimePicker
                value={dateTime}
                onChange={setTime}
                className={classNames(styles.kuiTimePicker, styles.p0)}
              />
            </div>
          </div>
          {/* MODAL FOOTER */}
          <div className={styles.modalFooter}>
            <p
              className={classNames('kui-caption', styles.datePreviewText, {
                [styles.error]: hasDateTimeError,
              })}
            >
              <Icon
                size={20}
                className={styles.datePreviewIcon}
                name="schedule"
              />
              {formatToReadableTimezone(date)}
            </p>
            <div>
              <Button variant="tertiary" onClick={toggle}>
                Cancel
              </Button>

              <Button
                className={styles.ml2}
                disabled={hasDateTimeError}
                onClick={onApplyDate}
              >
                Apply
              </Button>
            </div>
          </div>
        </Dialog.Panel>
      </Modal>
    </>
  )
}

export default ContactSchedular
