import React from 'react'

import classnames from 'classnames'

import { useThumbnail } from '../../common/hooks/services/inboxService/useThumbnail'
import { Attachment, Message } from '../../types'
import FailedThumbnail from './FailedThumbnail'
import LoadingThumbnail from './LoadingThumbnail'
import styles from './Message.module.scss'
import { MessageLabel } from './MessageLabel'

interface Props {
  attachment: Attachment
  message: Message
  sender?: string
  onClick?: () => void
}

export default function ImageMessageContainer({
  attachment,
  message,
  sender,
  onClick,
}: Props) {
  const { data, isLoading, error } = useThumbnail({
    messageId: message.id,
    attachmentId: attachment.id,
  })

  let content
  if (isLoading) {
    content = <LoadingThumbnail />
  } else if (error || typeof data !== 'string') {
    content = <FailedThumbnail />
  } else {
    content = (
      <img
        className={styles.thumbnail}
        src={data}
        alt="Attachment Thumbnail Preview"
      />
    )
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(
        styles.messageContainer,
        styles.link,
        message.outbound ? styles.outbound : styles.inbound,
      )}
    >
      {content}
      <MessageLabel sender={sender} message={message} />
    </button>
  )
}
