import React, { useEffect, useRef } from 'react'

import { Loader } from '@kenect-ut/kenect-ui-kit'

import BodySmall from '../common/components/typography/BodySmall'
import useConversationQueueSummary, {
  useSubscribeToQueue,
} from '../common/hooks/services/inboxService/useConversationQueueSummary'
import useClaimConversation from '../common/hooks/services/managedConversationService/useClaimConversation'
import usePlaySound from '../common/hooks/usePlaySound'
import { ConversationQueueSummary } from '../types'
import styles from './ConversationQueueContainer.module.scss'
import SelectNextConversation from './SelectNextConversation'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sound = require('./sound.mp3')

function isNewLead(
  current?: ConversationQueueSummary,
  previous?: ConversationQueueSummary,
) {
  return (
    current?.queueStartedAt !== undefined &&
    current.queueDepth !== undefined &&
    current.queueDepth > 0 &&
    previous?.queueDepth !== undefined &&
    (previous.queueDepth < current.queueDepth ||
      (previous.queueStartedAt !== current.queueStartedAt &&
        previous.queueDepth === current.queueDepth))
  )
}

function useNotifyOfNewLead(data?: ConversationQueueSummary) {
  const previous = useRef(data)
  const playSound = usePlaySound(sound)
  useEffect(() => {
    if (isNewLead(data, previous.current)) {
      playSound()
    }
    previous.current = data
  }, [data, playSound])
}

export function ConversationQueueContainer() {
  const { data: queueSummary, isLoading } = useConversationQueueSummary()
  const { mutate: claimConversation, isLoading: isClaiming } =
    useClaimConversation()
  useNotifyOfNewLead(queueSummary)
  useSubscribeToQueue()

  return (
    <section className={styles.conversations}>
      <div className={styles.incoming}>
        <BodySmall>INCOMING LEADS</BodySmall>
        {!isLoading && (
          <BodySmall>
            <b>{queueSummary?.queueDepth ?? 0}</b> waiting
          </BodySmall>
        )}
      </div>
      {isLoading && (
        <div className={styles.loadingView}>
          <Loader size="6px" />
        </div>
      )}
      {!!queueSummary?.queueDepth && (
        <SelectNextConversation
          isLoading={isClaiming}
          onClick={claimConversation}
          datetime={queueSummary?.queueStartedAt}
        />
      )}
    </section>
  )
}
