import React from 'react'

import { Button, Icon } from '@kenect-ut/kenect-ui-kit'

import { useAuth } from '../../auth/AuthProvider'
import styles from './NavBar.module.scss'

export function NavBar() {
  const { logout } = useAuth()
  return (
    <nav className={styles.navbar}>
      <div className={styles.leftSideNav}>
        <img
          className={styles.kenectLogo}
          src="/img/kenect.svg"
          alt="Kenect"
          title="Kenect"
          height={19}
          width={115}
        />
        <p className={styles.header}>Managed Conversations</p>
      </div>
      <div>
        <Button
          iconOnly
          variant="tertiary"
          className={styles.navItem}
          title="Logout"
          onClick={logout}
        >
          <Icon name="sign-out" />
        </Button>
      </div>
    </nav>
  )
}
