import apiFetch from '../../../auth/apiFetch'

export function assignTeam({
  conversationId,
  teamId,
}: {
  conversationId: number
  teamId: number
}) {
  return apiFetch(
    `${process.env.REACT_APP_INBOX_SERVICE_URL}/managed-conversations/${conversationId}/assignment`,
    {
      method: 'PATCH',
      body: JSON.stringify({ assignedTeamId: teamId }),
    },
  )
}
