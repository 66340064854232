import { initializeApp } from 'firebase/app'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_KENECT_UI_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_KENECT_UI_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_KENECT_UI_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_KENECT_UI_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_KENECT_UI_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_KENECT_UI_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_KENECT_UI_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_KENECT_UI_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

export default app
