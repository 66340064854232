import { useEffect, useState } from 'react'

import { DateTime } from 'luxon'

function getDifferenceFromNow(datetime: string) {
  return DateTime.now().diff(DateTime.fromISO(datetime))
}

export default function useDurationSince(datetime: string) {
  const [diff, setDiff] = useState(getDifferenceFromNow(datetime))
  useEffect(() => {
    setDiff(getDifferenceFromNow(datetime))
    const interval = setInterval(() => {
      setDiff(getDifferenceFromNow(datetime))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [datetime])
  return diff
}
