import React from 'react'

import { Icon, ModalAlert } from '@kenect-ut/kenect-ui-kit'

import { useUser } from '../common/hooks/api/authService/useUser'
import useAssignedConversations from '../common/hooks/services/managedConversationService/useAssignedConversations'
import useCloseConversation from '../common/hooks/services/managedConversationService/useCloseConversation'
import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import useModal from '../common/hooks/useModal'
import { formatContactName } from '../common/utils/formatters'
import { useContact } from '../contact/hooks'
import InfoBar from './InfoBar'

function getInfoBarTitle(count?: number) {
  return count
    ? 'Please select a conversation or new lead to continue your session.'
    : 'Please Select Next Visitor from Incoming Leads to begin your session.'
}

const initialMessageTypeMap: { [key: string]: string } = {
  SMS: 'mobile device',
  WEB_FORM: 'Kenect leads widget',
}

export default function ConversationSourceContainer() {
  const conversationId = useCurrentConversationId()
  const { data: conversation } = useConversation(conversationId)
  const { data: contact, isLoading: isLoadingContact } = useContact(
    conversation?.contactIds?.[0],
  )
  const { data: conversations, isLoading: isLoadingConversations } =
    useAssignedConversations()
  const { isVisible, show, hide } = useModal()
  const { mutateAsync: closeConversation } = useCloseConversation()
  const { data: user } = useUser()

  if (!conversationId) {
    return (
      <InfoBar
        isLoading={isLoadingConversations}
        title={`Welcome, ${user?.displayName || 'Agent'}!`}
        subtitle={getInfoBarTitle(conversations?.length)}
      />
    )
  }

  const source =
    conversation?.initialMessageType &&
    initialMessageTypeMap[conversation.initialMessageType]
  return (
    <>
      <InfoBar
        isLoading={isLoadingContact}
        title={formatContactName(contact)}
        subtitle={
          source && (
            <>
              began texting from <strong>{source}</strong>
            </>
          )
        }
        onClose={show}
      />
      {/* @ts-expect-error $KUI PROPS ERROR */}
      <ModalAlert
        isOpen={isVisible}
        closeButtonLabel="Cancel"
        color="red"
        id="alert"
        icon={<Icon name="alert-triangle" size={20} />}
        message="This conversation will be closed and archived in the customer’s Kenect app. You will no longer be able to access this conversation."
        onCloseButtonClicked={hide}
        primaryButtonLabel="End Conversation"
        title="Are you sure you want to end the conversation?"
        onPrimaryButtonClicked={async () => {
          if (conversation?.managedConversationId) {
            await closeConversation(conversation.managedConversationId)
          }
          hide()
        }}
      />
    </>
  )
}
