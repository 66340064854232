import apiFetch from '../../../auth/apiFetch'

export function claimConversation() {
  return apiFetch<number>(
    `${process.env.REACT_APP_MANAGED_CONVERSATIONS_SERVICE_URL}/unassigned-managed-conversations/claim`,
    {
      method: 'POST',
    },
  )
}
