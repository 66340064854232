import apiFetch from '../auth/apiFetch'
import type { Contact, LocationTeam } from '../types'
import type { UpsertContactDto } from './contactForm.utils'

// TODO: replace file with actual managed texting api

export async function getContactsByIds(ids: number[]) {
  const contacts = await apiFetch<Contact[]>(
    `${process.env.REACT_APP_LOCATION_SERVICE_URL}/managed-contacts/${ids.join(
      ',',
    )}`,
  )
  return contacts.reduce((byId: { [id: number]: Contact }, contact) => {
    // eslint-disable-next-line no-param-reassign
    byId[contact.id] = contact
    return byId
  }, {})
}

export const upsertContact = (contact: UpsertContactDto) =>
  apiFetch<Contact>(
    `${process.env.REACT_APP_LOCATION_SERVICE_URL}/managed-contacts`,
    {
      method: 'POST',
      body: JSON.stringify(contact),
    },
  )

export const getLocationTeams = (locationId: number) =>
  apiFetch<LocationTeam[]>(
    `${process.env.REACT_APP_INBOX_SERVICE_URL}/managed-locations/${locationId}/teams`,
  )

export const markAsQualified = (conversationId: number) =>
  apiFetch<void>(
    `${process.env.REACT_APP_MANAGED_CONVERSATIONS_SERVICE_URL}/assigned-managed-conversations/${conversationId}/mark-as-qualified`,
    {
      method: 'POST',
    },
  )
