import { useCallback, useState } from 'react'

export default function useModal() {
  const [isVisible, setIsVisible] = useState(false)

  const hide = useCallback(() => setIsVisible(false), [])

  const show = useCallback(() => setIsVisible(true), [])

  const toggle = useCallback(
    () => (isVisible ? hide() : show()),
    [isVisible, hide, show],
  )

  return {
    isVisible,
    show,
    hide,
    toggle,
  }
}

export type UseModalReturn = ReturnType<typeof useModal>
