import React, { useRef, useState } from 'react'

import { Button, Icon, Tooltip } from '@kenect-ut/kenect-ui-kit'
import type { IconNames } from '@kenect-ut/kenect-ui-kit/lib/components/Icon/iconNames'

import { ReplyMessageTemplate } from '../../types'
import ActionPopOver from './ActionPopOver'

type ActionButtonItemProps = {
  id: string
  iconName: IconNames
  locationId?: number
  toolTipContent: string
  searchPlaceholder: string
  onSelectOption: (option: ReplyMessageTemplate) => void
  templates: ReplyMessageTemplate[]
  disabled?: boolean
  buttonClassNames?: string
}

function ActionButtonItem({
  id,
  disabled = false,
  iconName,
  templates,
  locationId,
  toolTipContent,
  onSelectOption,
  buttonClassNames,
  searchPlaceholder,
}: ActionButtonItemProps) {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const popoverRef = useRef(null)

  const handleSearchInputChange = (term: string) => setSearchTerm(term)
  const handleTogglePopover = () => setPopoverOpen((prev) => !prev)
  const handleOptionSelection = (template: ReplyMessageTemplate) => {
    onSelectOption?.(template)
    handleTogglePopover()
  }

  return (
    <div>
      <Tooltip placement="top" showArrow content={toolTipContent}>
        <Button
          id={`actionButton_${id}`}
          ref={popoverRef}
          iconOnly
          variant="tertiary"
          className={buttonClassNames}
          disabled={disabled}
          onClick={handleTogglePopover}
          data-tracking-id={`compositionArea-${id}-actionButton`}
          data-testid={`compositionArea-${id}-actionButton`}
        >
          <Icon name={iconName} />
        </Button>
      </Tooltip>
      {!disabled && (
        <ActionPopOver
          popoverType={id}
          templates={templates}
          popoverRef={popoverRef}
          locationId={locationId}
          searchTerm={searchTerm}
          popoverOpen={popoverOpen}
          searchPlaceholder={searchPlaceholder}
          handleTogglePopover={handleTogglePopover}
          handleOptionSelection={handleOptionSelection}
          handleSearchInputChange={handleSearchInputChange}
        />
      )}
    </div>
  )
}

export default ActionButtonItem
