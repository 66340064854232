import React from 'react'

import { useParams } from 'react-router-dom'

import { AssignedConversationsContainer } from '../assignedConversations/AssignedConversationsContainer'
import ErrorBoundary from '../common/components/ErrorBoundary'
import CompositionAreaContainer from '../compositionArea/CompositionAreaContainer'
import { ContactContainer } from '../contact/ContactContainer'
import { ConversationQueueContainer } from '../conversationQueue/ConversationQueueContainer'
import ConversationSourceContainer from '../conversationSource/ConversationSourceContainer'
import { DealerContainer } from '../dealer/DealerContainer'
import { MessagesContainer } from '../messages/MessagesContainer'
import { CenterPanel } from './layout/CenterPanel'
import { ConversationsPanel } from './layout/ConversationsPanel'
import { DrawerLayout } from './layout/DrawerLayout'
import { Messenger } from './layout/Messenger'
import { NoConversationSelected } from './NoConversationSelected/NoConversationSelected'

export function Dashboard() {
  const { conversationId } = useParams<{ conversationId?: string }>()

  return (
    <DrawerLayout
      rightDrawer={
        <ErrorBoundary>
          <ContactContainer />
        </ErrorBoundary>
      }
    >
      <ConversationsPanel>
        <ErrorBoundary>
          <AssignedConversationsContainer />
        </ErrorBoundary>
        <ErrorBoundary>
          <ConversationQueueContainer />
        </ErrorBoundary>
      </ConversationsPanel>
      <CenterPanel>
        <ErrorBoundary>
          <ConversationSourceContainer />
          {!conversationId ? (
            <NoConversationSelected />
          ) : (
            <>
              <Messenger>
                <MessagesContainer />
                <CompositionAreaContainer />
              </Messenger>
              <ErrorBoundary>
                <DealerContainer />
              </ErrorBoundary>
            </>
          )}
        </ErrorBoundary>
      </CenterPanel>
    </DrawerLayout>
  )
}
