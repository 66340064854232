import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import useLocalStorage from '../../common/hooks/useLocalStorage'
import DragHandle from './DragHandle'
import styles from './DrawerLayout.module.scss'
import { NavBar } from './NavBar'

const startingDrawerWidth = 360
const minDrawerWidth = 320
const maxDrawerWidth = 520

export function DrawerLayout({
  children,
  rightDrawer,
}: {
  children: ReactNode
  rightDrawer: ReactNode
}) {
  const drawerElement = useRef<HTMLDivElement>(null)
  const [isResizing, setIsResizing] = useState(false)

  const [drawerWidth, setListViewWidth] = useLocalStorage<number>(
    'drawerWidth',
    startingDrawerWidth,
  )
  useEffect(() => {
    setListViewWidth(Math.min(+drawerWidth, document.body.clientWidth))
  }, [drawerWidth, setListViewWidth])
  const measuredOrderedViewWidth = drawerElement.current
    ? drawerElement.current?.offsetWidth
    : drawerWidth
  const handleResizeStart = useCallback(() => {
    setIsResizing(true)
  }, [])
  const handleResizeEnd = useCallback(
    (diffX: number) => {
      setIsResizing(false)
      const calculatedWidth = Math.min(
        measuredOrderedViewWidth - diffX,
        document.body.clientWidth,
      )
      setListViewWidth(Math.min(maxDrawerWidth, calculatedWidth))
    },
    [measuredOrderedViewWidth, setListViewWidth],
  )

  return (
    <div className={styles.container}>
      <NavBar />
      <section className={styles.content}>
        {children}
        <div
          className={styles.drawerHandle}
          style={{
            maxWidth: maxDrawerWidth,
            minWidth: minDrawerWidth,
            width: Math.min(maxDrawerWidth, drawerWidth),
            cursor: isResizing ? 'col-resize' : 'inherit',
          }}
          ref={drawerElement}
        >
          <DragHandle
            onDragStart={handleResizeStart}
            onDragEnd={handleResizeEnd}
          />
          {rightDrawer}
        </div>
      </section>
    </div>
  )
}
