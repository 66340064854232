import { useEffect, useMemo } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import debounce from 'lodash/debounce'

import { getMessages } from '../../../services/inboxService/messages'
import app from '../../../utils/firebase'

export const MESSAGES = 'MESSAGES'

const LIMIT = 20

export function useMessages(conversationId?: number) {
  return useInfiniteQuery(
    [MESSAGES, conversationId],
    ({ pageParam = 0 }) => {
      return conversationId
        ? getMessages(conversationId, LIMIT, pageParam * LIMIT)
        : []
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.length < LIMIT ? undefined : allPages.length
      },
      enabled: !!conversationId,
      useErrorBoundary: true,
    },
  )
}

export function useSubscribeToMessages(conversationId?: number) {
  const query = useMessages(conversationId)
  const debouncedRefetch = useMemo(
    () => debounce(query.refetch, 300),
    [query.refetch],
  )
  useEffect(() => {
    if (!conversationId) {
      return undefined
    }
    return onSnapshot(
      doc(getFirestore(app), 'conversations', conversationId.toString()),
      () => {
        debouncedRefetch()
      },
    )
  }, [debouncedRefetch, conversationId])
}
