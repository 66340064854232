import React from 'react'

import classnames from 'classnames'

import Timer from '../common/components/Timer/Timer'
import SubtitleLarge from '../common/components/typography/SubtitleLarge'
import useDurationSince from '../common/hooks/useDurationSince'
import styles from './SelectNextConversation.module.scss'

export default function SelectNextConversation({
  datetime,
  onClick,
  isLoading,
}: {
  datetime: string
  onClick: () => void
  isLoading?: boolean
}) {
  const diff = useDurationSince(datetime)
  const millisSince = diff.toMillis()
  const isWarning = millisSince > 10 * 1000
  const isDanger = millisSince > 20 * 1000
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isLoading}
      className={classnames(
        styles.button,
        isWarning && styles.warning,
        isDanger && styles.danger,
        isLoading && styles.claiming,
      )}
    >
      <SubtitleLarge>Select Next Visitor</SubtitleLarge>
      <Timer time={diff.toFormat('mm:ss')} />
    </button>
  )
}
