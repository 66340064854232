import React, { PropsWithChildren } from 'react'

import NoInternetConnectionError from '../errors/NoInternetConnectionError'
import { logError } from '../utils/logError'
import ErrorView from './ErrorView'

interface State {
  error: Error | null
}

type Props = PropsWithChildren<unknown>

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string
    },
  ) {
    logError(error, error.message, errorInfo)
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    if (error instanceof NoInternetConnectionError) {
      return (
        <ErrorView
          title="No internet connection,"
          message="Please check your internet connection and try again."
        />
      )
    }
    if (error) {
      return <ErrorView />
    }

    return children
  }
}
