import React from 'react'

import { Icon } from '@kenect-ut/kenect-ui-kit'

import BodySmall from '../../common/components/typography/BodySmall'
import styles from './Message.module.scss'

export default function FailedThumbnail({ text = 'Failed to Load Preview' }) {
  return (
    <div className={styles.loadingThumbnail}>
      <Icon name="alert-triangle" size={24} />
      <BodySmall>{text}</BodySmall>
    </div>
  )
}
