import React, { useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Icon } from '@kenect-ut/kenect-ui-kit'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import InputFieldArea from '../common/components/Form/InputFieldArea'
import SubtitleSmall from '../common/components/typography/SubtitleSmall'
import type { Contact } from '../types'
import styles from './ContactNotes.module.scss'
import ContactSchedular from './ContactScheduler'

export type ContactNotesAndAppointmentProps = {
  contact?: Contact
  disabled: boolean
  onAddEditNotes: (p: { note?: string }) => void
}

interface ContactNotesData {
  note?: string
}

const NotesSchema = yup.object({
  note: yup.string().optional(),
})

function formatDate(date: Date) {
  const luxDate = DateTime.fromISO(date.toISOString())
  return `${luxDate.toLocaleString({
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })} at ${luxDate.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
  })}`
}

function ContactNotesAndAppointment({
  contact,
  disabled,
  onAddEditNotes,
}: ContactNotesAndAppointmentProps) {
  const [hasNotes, setHasNotes] = useState(!!contact?.note)
  const initialValues = useRef({ note: contact?.note ?? '' })
  useEffect(() => {
    initialValues.current = { note: contact?.note ?? '' }
  }, [contact])

  const { control, setFocus, getValues, reset, setValue } =
    useForm<ContactNotesData>({
      resolver: yupResolver(NotesSchema),
      defaultValues: initialValues.current,
    })

  useEffect(() => {
    reset(initialValues.current)
    setHasNotes(!!initialValues.current.note)
  }, [reset, contact?.id])

  const handOpenNotes = () => {
    setHasNotes(true)
    setTimeout(() => {
      setFocus('note')
    }, 50)
  }

  const handleAutoSave = async () => {
    const formData = getValues()
    try {
      const validated = await NotesSchema.validate(formData)
      onAddEditNotes({ note: validated.note })
    } catch (error) {
      // just validation errors
    }
  }

  const onDateSelect = (date: Date) => {
    const { note } = getValues()
    const formattedDate = formatDate(date)
    const notesWithDateAdded = note?.length
      ? `Appointment Scheduled:\n${formattedDate}\n\n${note}`
      : `Appointment Scheduled:\n${formattedDate}`

    setValue('note', notesWithDateAdded, {
      shouldValidate: true,
      shouldDirty: true,
    })

    setHasNotes(true)
    handleAutoSave()
  }

  return (
    <>
      <SubtitleSmall className={styles.blockHeader}>Notes</SubtitleSmall>
      <form className={styles.notesForm} onChange={handleAutoSave}>
        {!hasNotes && (
          <div>
            <Button
              disabled={disabled}
              onClick={handOpenNotes}
              className={styles.notesButton}
              variant="tertiary"
            >
              <Icon name="add" className={styles.buttonIcon} />
              <p>
                Add notes <em className={styles.requiredSpan}>*required</em>
              </p>
            </Button>
          </div>
        )}
        <InputFieldArea
          name="note"
          control={control}
          id="note"
          label="Notes"
          outerClassName={classNames(styles.notesAreaOuter, {
            [styles.display]: hasNotes,
          })}
          className={classNames(
            styles.removeInputContainerStyles,
            styles.notesAreaInput,
          )}
          labelClassName={styles.removeInputLabelStyle}
          disabled={disabled}
        />
      </form>
      <ContactSchedular contact={contact} onDateSelect={onDateSelect} />
    </>
  )
}

export default ContactNotesAndAppointment
