import React, { useMemo } from 'react'

import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { LoadingView } from '../common/components/LoadingView'
import NoInternetConnectionError from '../common/errors/NoInternetConnectionError'
import { useUser } from '../common/hooks/api/authService/useUser'
import { useAuth } from './AuthProvider'
import { checkPermissions } from './checkPermissions'

const VIEW_MANAGED_CONVERSATIONS = 'VIEW_MANAGED_CONVERSATIONS'

function RequireManagedTextingPermissions({
  children,
}: {
  children: JSX.Element
}) {
  const { logout } = useAuth()
  const { data, isLoading, isPaused } = useUser()

  // see https://github.com/TanStack/query/issues/2927#issuecomment-1073113833
  if (isLoading && isPaused) {
    throw new NoInternetConnectionError()
  }

  const hasAccess = useMemo(
    () =>
      checkPermissions({
        requiredPermissions: [VIEW_MANAGED_CONVERSATIONS],
        permissionsList: data?.permissionsList ?? {},
        userRoles: data?.userRoles ?? [],
      }),
    [data],
  )

  if (isLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <LoadingView />
      </div>
    )
  }

  if (!isLoading && !hasAccess) {
    return (
      <div>
        <button type="button" onClick={logout}>
          Logout
        </button>
        You dont have permission for this!
      </div>
    )
  }

  return children
}

export function RequireAuth() {
  const { ready, user } = useAuth()
  const location = useLocation()

  if (!ready) {
    return (
      <div style={{ height: '100vh' }}>
        <LoadingView />
      </div>
    )
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <RequireManagedTextingPermissions>
      <Outlet />
    </RequireManagedTextingPermissions>
  )
}
