import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, HelpText } from '@kenect-ut/kenect-ui-kit'
import classnames from 'classnames'
import { FirebaseError } from 'firebase/app'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import InputField from '../common/components/Form/InputField'
import Heading from '../common/components/Heading'
import { useAuth } from './AuthProvider'
import style from './LoginContainer.module.scss'

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required()

interface FormData {
  email: string
  password: string
}

function getUserFriendlyError(error?: FirebaseError) {
  if (error?.message.includes('auth/wrong-password')) {
    return 'Wrong password'
  }
  if (error?.message.includes('auth/user-not-found')) {
    return 'User not found'
  }
  return error?.message || 'We encountered an error'
}

export function LoginPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const [error, setError] = useState<FirebaseError>()
  const [isLoading, setIsLoading] = useState(false)

  const { control, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const from = location.state?.from?.pathname || '/'

  function onSubmit({ email, password }: FormData) {
    setIsLoading(true)
    setError(undefined)
    auth
      .login(email, password)
      .then(() => {
        navigate(from, { replace: true })
      })
      .catch((err) => {
        setError(err)
        setIsLoading(false)
      })
  }

  return (
    <div className={style.loginWrapper}>
      <div className={style.loginContainer}>
        <div className={style.loginHeader}>
          <img
            className={style.kenectLogo}
            src="/img/kenect.svg"
            alt="Kenect Managed Texting Login"
            height={34}
            width={186}
          />
          <Heading
            as="h1"
            size="small"
            className={classnames(style.agentCompanyName, style.mt4)}
          >
            Managed Conversations
          </Heading>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={style.loginForm}>
          <InputField
            name="email"
            control={control}
            id="email"
            type="email"
            label="Email"
            useValidationSpacer
            containerClassName={style.wFull}
            className={style.wFull}
          />
          <InputField
            name="password"
            control={control}
            id="password"
            type="password"
            label="Password"
            useValidationSpacer
            containerClassName={style.wFull}
            className={style.wFull}
            autocomplete="current-password"
          />

          <Button
            className={style.loginButton}
            isLoading={isLoading}
            type="submit"
            size="large"
          >
            Sign In
          </Button>
          <HelpText color="var(--red-3)" className={style.loginAuthError}>
            {!!error && getUserFriendlyError(error)}
          </HelpText>
        </form>
      </div>
    </div>
  )
}
