import type { IPermissionsList, IUserRolesByLocationId } from '../types'

export function checkPermissions({
  requiredPermissions,
  userRoles,
  permissionsList,
  locationId,
}: {
  requiredPermissions: string[]
  permissionsList: IPermissionsList
  userRoles: IUserRolesByLocationId
  locationId?: number
}): boolean {
  if (locationId) {
    return (
      !!userRoles[locationId] &&
      requiredPermissions.some((reqPerm) =>
        permissionsList[reqPerm].some(
          (permByLoc) => permByLoc.locationId === locationId,
        ),
      )
    )
  }
  return Object.keys(permissionsList).some((assignedPerm) =>
    requiredPermissions.some((reqPerm) => reqPerm === assignedPerm),
  )
}
