export const stringComparator = (
  forCompare: string | string[],
  search?: string,
) => {
  if (!search) {
    return true
  }
  if (typeof forCompare === 'string') {
    return (
      forCompare.trim().toUpperCase().indexOf(search.trim().toUpperCase()) >= 0
    )
  }
  if (Array.isArray(forCompare)) {
    return forCompare.some(
      (term) =>
        typeof term === 'string' &&
        term.toUpperCase().indexOf(search.toUpperCase()) >= 0,
    )
  }
  return false
}
