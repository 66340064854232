import { DateTime } from 'luxon'

export interface IBusinessHour {
  // id: number
  // responseSettingId: number
  dayOfWeek: string
  startTime: string
  endTime: string
  // updatedDate: number
  // createdDate: number
}

export interface CombinedBusinessHours {
  dayOfWeek: string
  startTime: string
  endTime: string
}

export function combineLikeHours(
  businessHours: IBusinessHour[],
): CombinedBusinessHours[] {
  if (businessHours?.length <= 1) return businessHours
  const tempArr = businessHours.map(({ dayOfWeek, startTime, endTime }) => ({
    dayOfWeek,
    startTime,
    endTime,
  }))
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const startDay = tempArr.shift()!
  const combinedDays = tempArr.reduce(
    (prev, curr) => {
      const pvIdx = prev.length - 1
      // check for like start/end times
      if (
        prev[pvIdx]?.startTime === curr.startTime &&
        prev[pvIdx]?.endTime === curr.endTime
      ) {
        // replace last common dayOfWeek
        const splitDays = prev[pvIdx]?.dayOfWeek.split(' - ')
        // eslint-disable-next-line no-param-reassign
        prev[pvIdx].dayOfWeek = [splitDays[0], curr.dayOfWeek].join(' - ')
        return [...prev]
      }
      // else, add unique hours
      return [...prev, curr]
    },
    [startDay],
  )

  return combinedDays
}

/**
 * @param time time to format, must be `hh:mm:ss`
 * @returns formatted hour: 8:00 AM
 */
export function formatBusinessTime(time: string) {
  const isTopOfHour = time.split(':')[1] === '00' // get minutes
  const formatToDisplay = isTopOfHour ? 'ha' : 'h:mma' // Show hour and meridiem for topOfTheHour
  return DateTime.fromFormat(time, 'hh:mm:ss')
    .toFormat(formatToDisplay)
    .toString()
    .toLowerCase()
}

export function formatLeadTime(time: Date, incomingLeadTimeZone: string) {
  return DateTime.fromMillis(time.getTime())
    .setZone(incomingLeadTimeZone)
    .toFormat('h:mm a')
    .toString()
    .toLowerCase()
}
