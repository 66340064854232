import apiFetch from '../../../auth/apiFetch'
import { Message } from '../../../types'

export function getMessages(
  conversationId: number,
  limit: number,
  offset: number,
) {
  return apiFetch<Message[]>(
    `${
      process.env.REACT_APP_INBOX_SERVICE_URL
    }/assigned-managed-conversations/${conversationId}/messages?${new URLSearchParams(
      {
        limit: `${limit}`,
        offset: `${offset}`,
      },
    )}`,
  )
}

export const getMessageAttachmentThumbnail = async ({
  messageId,
  attachmentId,
}: {
  messageId: number
  attachmentId: number
}) => {
  return apiFetch<Blob>(
    `${process.env.REACT_APP_INBOX_SERVICE_URL}/managed-messages/${messageId}/attachments/${attachmentId}/thumbnail`,
  )
}

export const getMessageAttachment = ({
  messageId,
  attachmentId,
}: {
  messageId: number
  attachmentId: number
}) => {
  return apiFetch<Blob>(
    `${process.env.REACT_APP_INBOX_SERVICE_URL}/managed-messages/${messageId}/attachments/${attachmentId}`,
  )
}
