import { logError } from './utils/logError'

export function setAppBadge(count: number) {
  if ('setAppBadge' in navigator) {
    navigator.setAppBadge(count).catch((error) => {
      logError(error, 'Failed to update badge')
    })
  }
}
export function clearAppBadge() {
  if ('clearAppBadge' in navigator) {
    navigator.clearAppBadge().catch((error) => {
      logError(error, 'Failed to clear badge')
    })
  }
}
