import apiFetch from '../../../auth/apiFetch'
import { CreateMessageDto } from '../../../types'

interface ISendConversationMessageArgs {
  conversationId: number
  data: CreateMessageDto
}
export const sendConversationMessage = ({
  conversationId,
  data,
}: ISendConversationMessageArgs) => {
  return apiFetch<void>(
    `${process.env.REACT_APP_MESSAGE_SERVICE_URL_V3}/assigned-managed-conversations/${conversationId}/messages`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  )
}
