import { DateTime } from 'luxon'

export function validateDate(date: Date | string) {
  const currentTime = DateTime.local()
  const selectedDate = DateTime.fromISO(new Date(date).toISOString())
  // if the date is in the past ignoring time
  if (selectedDate.startOf('day') < currentTime.startOf('day')) {
    return {
      hasDateError: true,
      hasTimeError: false,
    }
  }
  // else if the date and time are in the past
  if (selectedDate.startOf('minute') < currentTime.startOf('minute')) {
    return {
      hasDateError: false,
      hasTimeError: true,
    }
  }
  return {
    hasDateError: false,
    hasTimeError: false,
  }
}
