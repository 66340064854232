import React from 'react'

import type { ReplyMessageTemplate } from '../../types'
import ActionButtonItem from './ActionButtonItem'

type TopActionsBarProps = {
  locationId?: number
  onOptionSelect: (option: ReplyMessageTemplate) => void
  resourceLinkTemplates: ReplyMessageTemplate[]
  quickReplyTemplates: ReplyMessageTemplate[]
}

function TopActionsBar({
  locationId,
  onOptionSelect,
  resourceLinkTemplates,
  quickReplyTemplates,
}: TopActionsBarProps) {
  return (
    <>
      <ActionButtonItem
        id="quickReplies"
        iconName="flash"
        locationId={locationId}
        toolTipContent="Quick Replies"
        searchPlaceholder="Search Quick Replies"
        onSelectOption={onOptionSelect}
        templates={quickReplyTemplates}
      />
      <ActionButtonItem
        id="resourceUrls"
        iconName="link"
        locationId={locationId}
        toolTipContent="Resource Links"
        searchPlaceholder="Search Resource Links"
        onSelectOption={onOptionSelect}
        templates={resourceLinkTemplates}
      />
    </>
  )
}

export default TopActionsBar
