export default class NoInternetConnectionError extends Error {
  constructor() {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super('No internet connection')

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NoInternetConnectionError)
    }

    this.name = 'NoInternetConnectionError'
  }
}
