import React from 'react'

import classNames from 'classnames'

interface HeadingProps {
  size?: 'small' | 'medium' | 'large'
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
  className?: string
  children?: React.ReactNode
}

function Heading({
  size = 'medium',
  as: Tag = 'h1',
  className,
  children,
}: HeadingProps) {
  return (
    <Tag className={classNames(`kui-title-${size}`, className)}>{children}</Tag>
  )
}

export default Heading
