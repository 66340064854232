import React from 'react'

import { Attachment, Message } from '../../types'
import AttachmentMessage from './AttachmentMessage'
import ImageMessageContainer from './ImageMessageContainer'
import TextMessage from './TextMessage'

function AttachmentComponent({
  attachment,
  message,
  sender,
  onClick,
}: {
  attachment: Attachment
  message: Message
  sender?: string
  onClick?: () => void
}) {
  if (attachment.contentType.includes('image/')) {
    return (
      <ImageMessageContainer
        onClick={onClick}
        attachment={attachment}
        message={message}
        sender={sender}
      />
    )
  }
  return (
    <AttachmentMessage
      attachment={attachment}
      message={message}
      sender={sender}
    />
  )
}

export default function AttachmentsMessage({
  message,
  sender,
  onClickMessage,
}: {
  message: Message
  sender?: string
  onClickMessage?: (m: Message, a: Attachment) => void
}) {
  const { attachments } = message
  return (
    <>
      {attachments.map((attachment) => (
        <AttachmentComponent
          key={attachment.id}
          sender={sender}
          message={message}
          attachment={attachment}
          onClick={() => onClickMessage?.(message, attachment)}
        />
      ))}
      {message.body && <TextMessage message={message} />}
    </>
  )
}
