import React from 'react'

import { DateTime } from 'luxon'

import Caption from '../../common/components/typography/Caption'
import { Message } from '../../types'
import styles from './Message.module.scss'

const FAILURE_STATUSES = [
  'FAILED',
  'UNDELIVERED',
  'FAILED_EXCEPTION',
  'FAILED_LANDLINE_EXCEPTION',
]

export function MessageLabel({
  message,
  sender,
}: {
  message: Message
  sender?: string
}) {
  let displayMessage
  const date = DateTime.fromISO(message.sentAt)
  const senderPrefix = message.outbound && sender ? `${sender} - ` : ''

  if (message.externalStatus === 'SENDING') {
    displayMessage = `${senderPrefix}${date.toFormat('hh:mm a')} - Sending`
  } else if (FAILURE_STATUSES.includes(message.externalStatus)) {
    displayMessage = (
      <>
        {`${senderPrefix}${date.toFormat('MM/dd/yy hh:mm a')} - `}
        <span className={styles.failed}>Failed</span>
      </>
    )
  } else {
    displayMessage = `${senderPrefix}${date.toFormat('MM/dd/yy hh:mm a')}`
  }
  return <Caption className={styles.sentAt}>{displayMessage}</Caption>
}
