import React from 'react'

import { Loader } from '@kenect-ut/kenect-ui-kit'

import styles from './LoadingView.module.scss'

export function LoadingView() {
  return (
    <div className={styles.view}>
      <Loader size="6px" />
    </div>
  )
}
