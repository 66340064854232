import type { ReplyMessageTemplate } from '../../types'

export const mockReplies: ReplyMessageTemplate[] = [
  {
    id: 1,
    organizationId: 1,
    locationId: 999,
    title:
      "Assurance | It's a pleasure to speak with you today ___! How may I be of assistance?",
    template:
      "It's a pleasure to speak with you today ___! How may I be of assistance?",
    type: 'QuickResponse',
    updatedDate: 1669145326,
    createdDate: 1669161600,
  },
  {
    id: 2,
    organizationId: 1,
    locationId: 999,
    title:
      'Assurance | I would be glad to help. One moment while I check on that for you.',
    template:
      'I would be glad to help. One moment while I check on that for you.',
    type: 'QuickResponse',
    updatedDate: 1669105315,
    createdDate: 1669161600,
  },
  {
    id: 3,
    organizationId: 1,
    locationId: 999,
    title:
      "Availability | That particular feature is one I am not able to confirm at the moment, but what I can do is have another team member check on that and follow up with you. What's the best phone number and email address to reach you?",
    template:
      "That particular feature is one I am not able to confirm at the moment, but what I can do is have another team member check on that and follow up with you. What's the best phone number and email address to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669088316,
    createdDate: 1669161600,
  },
  {
    id: 4,
    organizationId: 1,
    locationId: 999,
    title:
      "Availability | This is a brand new listing! It looks like we have not posted the photos yet but, if you are interested, I can put in a request to have our sales team send pictures to you directly. What's the best phone number and email address to reach you?",
    template:
      "This is a brand new listing! It looks like we have not posted the photos yet but, if you are interested, I can put in a request to have our sales team send pictures to you directly. What's the best phone number and email address to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669119872,
    createdDate: 1669161600,
  },
  {
    id: 5,
    organizationId: 1,
    locationId: 999,
    title:
      'Build Rapport | Congratulations on your graduation! That is a huge achievement!',
    template: 'Congratulations on your graduation! That is a huge achievement!',
    type: 'QuickResponse',
    updatedDate: 1669107723,
    createdDate: 1669161600,
  },
  {
    id: 6,
    organizationId: 1,
    locationId: 999,
    title:
      'Build Rapport | I can certainly check on military discounts for you. I sincerely appreciate your service to our country.',
    template:
      'I can certainly check on military discounts for you. I sincerely appreciate your service to our country.',
    type: 'QuickResponse',
    updatedDate: 1669122127,
    createdDate: 1669161600,
  },
  {
    id: 7,
    organizationId: 1,
    locationId: 999,
    title: 'Close | Are you still available to chat?',
    template: 'Are you still available to chat?',
    type: 'QuickResponse',
    updatedDate: 1669095945,
    createdDate: 1669161600,
  },
  {
    id: 8,
    organizationId: 1,
    locationId: 999,
    title:
      'Close | I will forward your information over right away and a member of our team will be contacting you as soon as they are available. May I be of further assistance at this time?',
    template:
      'I will forward your information over right away and a member of our team will be contacting you as soon as they are available. May I be of further assistance at this time?',
    type: 'QuickResponse',
    updatedDate: 1669141041,
    createdDate: 1669161600,
  },
  {
    id: 9,
    organizationId: 1,
    locationId: 999,
    title: 'Close | Is there anything else I may assist you with?',
    template: 'Is there anything else I may assist you with?',
    type: 'QuickResponse',
    updatedDate: 1669111282,
    createdDate: 1669161600,
  },
  {
    id: 10,
    organizationId: 1,
    locationId: 999,
    title:
      'Close | It has been a pleasure messaging with you. Thank you for messaging with us and please use our service again! Have a wonderful day!',
    template:
      'It has been a pleasure messaging with you. Thank you for messaging with us and please use our service again! Have a wonderful day!',
    type: 'QuickResponse',
    updatedDate: 1669106106,
    createdDate: 1669161600,
  },
  {
    id: 11,
    organizationId: 1,
    locationId: 999,
    title: 'Close | May I be of further assistance on chat?',
    template: 'May I be of further assistance on chat?',
    type: 'QuickResponse',
    updatedDate: 1669089287,
    createdDate: 1669161600,
  },
  {
    id: 12,
    organizationId: 1,
    locationId: 999,
    title: 'Complimenting VOI | Thank you for your loyalty!',
    template: 'Thank you for your loyalty!',
    type: 'QuickResponse',
    updatedDate: 1669145320,
    createdDate: 1669161600,
  },
  {
    id: 13,
    organizationId: 1,
    locationId: 999,
    title: 'Complimenting VOI | That is a fantastic choice!',
    template: 'That is a fantastic choice!',
    type: 'QuickResponse',
    updatedDate: 1669128199,
    createdDate: 1669161600,
  },
  {
    id: 14,
    organizationId: 1,
    locationId: 999,
    title:
      'Complimenting VOI | That is one of my favorite models in the lineup!',
    template: 'That is one of my favorite models in the lineup!',
    type: 'QuickResponse',
    updatedDate: 1669128827,
    createdDate: 1669161600,
  },
  {
    id: 15,
    organizationId: 1,
    locationId: 999,
    title:
      "Contact Info | If it would be more convenient, I would be happy to have them follow up with you by email instead. What's the best email address to send that information to?",
    template:
      "If it would be more convenient, I would be happy to have them follow up with you by email instead. What's the best email address to send that information to?",
    type: 'QuickResponse',
    updatedDate: 1669103330,
    createdDate: 1669161600,
  },
  {
    id: 16,
    organizationId: 1,
    locationId: 999,
    title:
      'Contact Info | Is there also an email address we can use as a secondary form of contact?',
    template:
      'Is there also an email address we can use as a secondary form of contact?',
    type: 'QuickResponse',
    updatedDate: 1669144945,
    createdDate: 1669161600,
  },
  {
    id: 17,
    organizationId: 1,
    locationId: 999,
    title:
      'Contact Info | May I ask your first and last name so we can properly address you?',
    template:
      'May I ask your first and last name so we can properly address you?',
    type: 'QuickResponse',
    updatedDate: 1669075946,
    createdDate: 1669161600,
  },
  {
    id: 18,
    organizationId: 1,
    locationId: 999,
    title:
      "Contact Info | What's the best phone number and email address to reach you?",
    template: "What's the best phone number and email address to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669077030,
    createdDate: 1669161600,
  },
  {
    id: 19,
    organizationId: 1,
    locationId: 999,
    title:
      'Greeting | Hello, this is {AGENT}. Thank you for messaging with us today! I would be happy to assist you with that today, ____! Just a moment while I check on that for you.',
    template:
      'Hello, this is {AGENT}. Thank you for messaging with us today! I would be happy to assist you with that today, ____! Just a moment while I check on that for you.',
    type: 'QuickResponse',
    updatedDate: 1669106780,
    createdDate: 1669161600,
  },
  {
    id: 20,
    organizationId: 1,
    locationId: 999,
    title:
      'Greeting | Hello, this is {AGENT}. Thank you for messaging with us today! I would be happy to assist you with that today. Who do I have the pleasure of speaking with?',
    template:
      'Hello, this is {AGENT}. Thank you for messaging with us today! I would be happy to assist you with that today. Who do I have the pleasure of speaking with?',
    type: 'QuickResponse',
    updatedDate: 1669136379,
    createdDate: 1669161600,
  },
  {
    id: 21,
    organizationId: 1,
    locationId: 999,
    title:
      'Greeting | Hello, this is {AGENT}. Thank you for messaging with us today! Who do I have the pleasure of speaking with?',
    template:
      'Hello, this is {AGENT}. Thank you for messaging with us today! Who do I have the pleasure of speaking with?',
    type: 'QuickResponse',
    updatedDate: 1669134875,
    createdDate: 1669161600,
  },
  {
    id: 22,
    organizationId: 1,
    locationId: 999,
    title:
      "Greeting | Hello, this is {AGENT}. Thank you for messaging with us today. It's a pleasure to speak with you, ____. How may I assist you today?",
    template:
      "Hello, this is {AGENT}. Thank you for messaging with us today. It's a pleasure to speak with you, ____. How may I assist you today?",
    type: 'QuickResponse',
    updatedDate: 1669127467,
    createdDate: 1669161600,
  },
  {
    id: 23,
    organizationId: 1,
    locationId: 999,
    title:
      "Inventory | Great news! I see it is still listed as available. Just to be sure there are no pending deals, I would like to have a team member confirm and follow up with you. What's the best phone number and email address to reach you?",
    template:
      "Great news! I see it is still listed as available. Just to be sure there are no pending deals, I would like to have a team member confirm and follow up with you. What's the best phone number and email address to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669128589,
    createdDate: 1669161600,
  },
  {
    id: 24,
    organizationId: 1,
    locationId: 999,
    title:
      "Inventory | What I would like to do is have a member of our sales team put together a list of available vehicles based on your preferences. What's the best phone number and email address for them to reach you?",
    template:
      "What I would like to do is have a member of our sales team put together a list of available vehicles based on your preferences. What's the best phone number and email address for them to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669109567,
    createdDate: 1669161600,
  },
  {
    id: 25,
    organizationId: 1,
    locationId: 999,
    title:
      'Invite | I would love to invite you to take a test drive! When are you available to come to the dealership?',
    template:
      'I would love to invite you to take a test drive! When are you available to come to the dealership?',
    type: 'QuickResponse',
    updatedDate: 1669136665,
    createdDate: 1669161600,
  },
  {
    id: 26,
    organizationId: 1,
    locationId: 999,
    title:
      'Invite | When is the soonest you would be available to come in for a test drive and review your options?',
    template:
      'When is the soonest you would be available to come in for a test drive and review your options?',
    type: 'QuickResponse',
    updatedDate: 1669101370,
    createdDate: 1669161600,
  },
  {
    id: 27,
    organizationId: 1,
    locationId: 999,
    title:
      'Invite | When would you be available to bring in your trade for an appraisal while you review your options with our Sales Team?',
    template:
      'When would you be available to bring in your trade for an appraisal while you review your options with our Sales Team?',
    type: 'QuickResponse',
    updatedDate: 1669117249,
    createdDate: 1669161600,
  },
  {
    id: 28,
    organizationId: 1,
    locationId: 999,
    title:
      'Name | I am happy to assist you with that! Who do I have the pleasure of messaging with?',
    template:
      'I am happy to assist you with that! Who do I have the pleasure of messaging with?',
    type: 'QuickResponse',
    updatedDate: 1669128526,
    createdDate: 1669161600,
  },
  {
    id: 29,
    organizationId: 1,
    locationId: 999,
    title:
      "Pricing | We always have several specials running! Let me check with a Sales Specialist about the offer you are interested in. What's the best phone number and email address to reach you?",
    template:
      "We always have several specials running! Let me check with a Sales Specialist about the offer you are interested in. What's the best phone number and email address to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669129519,
    createdDate: 1669161600,
  },
  {
    id: 30,
    organizationId: 1,
    locationId: 999,
    title:
      "Pricing | We pride ourselves on competitive pricing. Let me check with a team member and see what we can work out for you. What's the best phone number and email address to reach you?",
    template:
      "We pride ourselves on competitive pricing. Let me check with a team member and see what we can work out for you. What's the best phone number and email address to reach you?",
    type: 'QuickResponse',
    updatedDate: 1669126886,
    createdDate: 1669161600,
  },
  {
    id: 31,
    organizationId: 1,
    locationId: 999,
    title: 'Research | One moment while I look into that for you.',
    template: 'One moment while I look into that for you.',
    type: 'QuickResponse',
    updatedDate: 1669115001,
    createdDate: 1669161600,
  },
  {
    id: 32,
    organizationId: 1,
    locationId: 999,
    title:
      'Research | Thank you very much for your patience. I am still checking on that for you.',
    template:
      'Thank you very much for your patience. I am still checking on that for you.',
    type: 'QuickResponse',
    updatedDate: 1669078203,
    createdDate: 1669161600,
  },
  {
    id: 33,
    organizationId: 1,
    locationId: 999,
    title: 'VOI/Probing | Do you have a price range in mind?',
    template: 'Do you have a price range in mind?',
    type: 'QuickResponse',
    updatedDate: 1669124443,
    createdDate: 1669161600,
  },
  {
    id: 34,
    organizationId: 1,
    locationId: 999,
    title:
      'VOI/Probing | Does this look like something you would be interested in?',
    template: 'Does this look like something you would be interested in?',
    type: 'QuickResponse',
    updatedDate: 1669098233,
    createdDate: 1669161600,
  },
]
