import React, { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

import styles from './Modal.module.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}

function Modal({ isOpen, onClose, children }: Props) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className={styles.dialogParent} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter={classNames(styles.easeOut, styles.duration300)}
          enterFrom={styles.opacity0}
          enterTo={styles.opacity100}
          leave={classNames(styles.easeIn, styles.duration200)}
          leaveFrom={styles.opacity100}
          leaveTo={styles.opacity0}
        >
          <div className={styles.backgroundOverlay} aria-hidden="true" />
        </Transition.Child>

        <div className={styles.modalContainer}>
          <div className={styles.modalWrapper}>
            <Transition.Child
              as={Fragment}
              enter={classNames(styles.easeOut, styles.duration300)}
              enterFrom={classNames(styles.opacity0, styles.scale95)}
              enterTo={classNames(styles.opacity100, styles.scale100)}
              leave={classNames(styles.easeIn, styles.duration200)}
              leaveFrom={classNames(styles.opacity100, styles.scale100)}
              leaveTo={classNames(styles.opacity0, styles.scale95)}
            >
              <Dialog.Panel className={styles.baseModal}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
