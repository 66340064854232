import React from 'react'

import { TextInput as KuiTextInput } from '@kenect-ut/kenect-ui-kit'
import type { Control } from 'react-hook-form'
import { useController } from 'react-hook-form'

import ValidationMessage from './ValidationMessage'

interface InputFieldProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  useValidationSpacer?: boolean
  containerClassName?: string
  [key: string]: unknown
}

function InputField({
  name,
  control,
  useValidationSpacer = false,
  containerClassName,
  ...rest
}: InputFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  return (
    <div className={containerClassName}>
      <KuiTextInput
        ref={ref}
        // @ts-expect-error $KUI PROPS ERROR
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <ValidationMessage
        error={error?.message}
        useSpacer={useValidationSpacer}
      />
    </div>
  )
}

export default InputField
