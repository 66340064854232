import React, { useMemo } from 'react'

import BodySmall from '../common/components/typography/BodySmall'
import {
  combineLikeHours,
  formatBusinessTime,
  formatLeadTime,
  IBusinessHour,
} from './dealer.utils'
import { useCurrentLeadTime } from './hooks'
import styles from './LocationHours.module.scss'

function LeadLocalTime({ timezone }: { timezone: string }) {
  const currentLeadTime = useCurrentLeadTime()
  return (
    <li className={styles.customerTimeZone}>
      <strong>{formatLeadTime(currentLeadTime, timezone)}</strong>{' '}
      <BodySmall className="timezone">
        current local time ({timezone})
      </BodySmall>
    </li>
  )
}

export function LocationBusinessHours({
  businessHours,
  incomingLeadTimeZone,
}: {
  businessHours: IBusinessHour[]
  incomingLeadTimeZone?: string
}) {
  const combinedBusinessHours = useMemo(
    () => combineLikeHours(businessHours),
    [businessHours],
  )
  return (
    <ul className={styles.businessHoursContainer}>
      {incomingLeadTimeZone && (
        <LeadLocalTime timezone={incomingLeadTimeZone} />
      )}
      {combinedBusinessHours?.map((day) => (
        <li key={day.dayOfWeek}>
          <BodySmall>
            {day.dayOfWeek}:{' '}
            <strong>{formatBusinessTime(day.startTime)} </strong>-{' '}
            <strong>{formatBusinessTime(day.endTime)}</strong>
          </BodySmall>
        </li>
      ))}
    </ul>
  )
}
