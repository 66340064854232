import React, { useCallback, useEffect, useRef, useState } from 'react'

import styles from './DragHandle.module.scss'

function DragHandle({
  onDragStart,
  onDragEnd,
}: {
  onDragStart: () => void
  onDragEnd: (deltaX: number) => void
}) {
  const dragElement = useRef<HTMLDivElement>(null)
  const dragStartPosition = useRef<number>()
  const [isBeingDragged, setIsBeingDragged] = useState(false)
  const [currentDragDiffX, setCurrentDragDiffX] = useState(0)

  useEffect(() => {
    const handlePageDrag = (event: DragEvent) => {
      if (isBeingDragged) {
        event.preventDefault()
        const diffX = event.clientX - (dragStartPosition.current || 0)
        setCurrentDragDiffX(diffX)
      }
    }

    document.addEventListener('dragover', handlePageDrag)
    return () => {
      document.removeEventListener('dragover', handlePageDrag)
    }
  }, [isBeingDragged])

  const handleDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'move'

      // Wrap in setTimeout due to a known issue in Chrome, where any DOM manipulation
      // in the dragStart event handler will cancel the drag event.
      // https://groups.google.com/a/chromium.org/g/chromium-bugs/c/YHs3orFC8Dc/m/ryT25b7J-NwJ
      setTimeout(() => {
        if (dragElement.current) {
          const handleRect = dragElement.current.getBoundingClientRect()
          dragStartPosition.current = (handleRect.left + handleRect.right) / 2
          setIsBeingDragged(true)
          if (onDragStart) onDragStart()
        }
      })
    },
    [onDragStart],
  )

  const handleDragEnd = useCallback(() => {
    dragStartPosition.current = undefined
    onDragEnd(currentDragDiffX)
    setCurrentDragDiffX(0)
    setIsBeingDragged(false)
  }, [onDragEnd, currentDragDiffX])

  return (
    <>
      <div
        className={styles.resizeHandle}
        ref={dragElement}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      />
      <div
        className={styles.resizeHandleGhost}
        style={{
          display: isBeingDragged ? 'block' : 'none',
          marginLeft: currentDragDiffX,
        }}
      />
    </>
  )
}

export default DragHandle
