import { redirect, useParams } from 'react-router-dom'

export default function useCurrentConversationId() {
  const { conversationId } = useParams<{ conversationId?: string }>()
  if (!conversationId) {
    redirect('/dashboard')
    return 0
  }

  return +conversationId
}
