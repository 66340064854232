import React, { useMemo } from 'react'

import { Navigate } from 'react-router-dom'

import { useUser } from '../common/hooks/api/authService/useUser'
import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import { useSendConversationMessage } from '../common/hooks/services/messagingService/useSendConversationMessage'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import type { IAuthenticatedUser, ReplyMessageTemplate } from '../types'
import styles from './CompositionAreaContainer.module.scss'
import CompositionForm from './form/CompositionForm'
import { mockReplies } from './form/mock'
import { useResourceOptions } from './hooks'

const quickReplies = mockReplies.filter((t) => t.type === 'QuickResponse')

function getDisplayName(user?: IAuthenticatedUser) {
  return user?.displayName ? user.displayName.split(' ')[0] : 'Agent'
}
function useQuickReplies() {
  const displayName = getDisplayName(useUser().data)
  return useMemo(
    () =>
      JSON.parse(
        JSON.stringify(quickReplies).replaceAll('{AGENT}', displayName),
      ),
    [displayName],
  ) as ReplyMessageTemplate[]
}

function CompositionAreaContainer() {
  const conversationId = useCurrentConversationId()
  const { data: conversation, isLoading } = useConversation(conversationId)
  const { mutateAsync: sendConversationResponse } = useSendConversationMessage()
  const resourceLinkTemplates = useResourceOptions(conversation?.locationId)
  const quickReplyTemplates = useQuickReplies()

  if (!isLoading && !conversation) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className={styles.compositionAreaWrapper}>
      <div className={styles.compositionAreaContainer}>
        <CompositionForm
          onSend={(data) =>
            sendConversationResponse({
              conversationId,
              data,
            })
          }
          conversation={conversation}
          quickReplyTemplates={quickReplyTemplates}
          resourceLinkTemplates={resourceLinkTemplates}
        />
      </div>
    </div>
  )
}

export default CompositionAreaContainer
